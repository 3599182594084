import { HStack, Icon, Text, VStack } from "@chakra-ui/react";
import { motion } from "framer-motion";
import { FiMail } from "react-icons/fi";

export default function Contact() {
  return (
    <motion.div
      initial={{ y: 20, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      transition={{
        delay: 0.2,
        duration: 1,
        ease: [0.6, -0.05, 0.01, 0.99],
      }}
    >
      <VStack pt="10" spacing="5" alignItems="left">
        <Text fontWeight="semibold">
          Please email us for all inquiries, thanks!
        </Text>
        <HStack
          _hover={{ color: "brand.500", transition: "0.3s", cursor: "pointer" }}
        >
          <Icon as={FiMail} color="inherit" />
          <Text fontWeight="semibold" color="inherit">
            team@babblenyc.com
          </Text>
        </HStack>
      </VStack>
    </motion.div>
  );
}
