import { Center, Heading } from "@chakra-ui/react";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";

export const Logo = () => {
  return (
    <motion.div
      initial={{ y: 20, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      transition={{
        duration: 1.2,
        ease: [0.6, -0.05, 0.01, 0.99],
      }}
    >
      <Heading color="brand.500" as={Link} to="/">
        babble
      </Heading>
    </motion.div>
  );
};
