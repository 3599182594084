import { extendTheme } from "@chakra-ui/react";

const theme = extendTheme({
  styles: {
    global: {
      body: {
        bg: "#fdfbf2", // cream colored bg
        color: "gray.700", // Dark grey text
      },
    },
  },
  fonts: {
    body: "Inconsolata, monospace",
    handwritten: "Gochi Hand, sans-serif",
    heading: "Gochi Hand, sans-serif",
    mono: "Inconsolata, monospace",
  },
  colors: {
    brand: {
      50: "#e7efff",
      100: "#c2d4ff",
      200: "#99b6ff",
      300: "#6f97ff",
      400: "#4d7aff",
      500: "#285FF5", // Primary brand color
      600: "#234fe0",
      700: "#1d3fcc",
      800: "#172fb8",
      900: "#111fa3",
    },
  },
  components: {
    Button: {
      baseStyle: {
        fontWeight: "bold",
      },
    },
  },
});

export default theme;
