import "./App.css";

import theme from "./theme";
import { ChakraProvider, Container, VStack } from "@chakra-ui/react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import Home from "./Pages/Home/Home";
import { Nav } from "./Components/TopNav/Nav";
import { Logo } from "./Components/TopNav/Logo";
import Locations from "./Pages/Locations/Locations";
import Contact from "./Pages/Contact/Contact";

function App() {
  return (
    <ChakraProvider theme={theme}>
      <BrowserRouter>
        <Container
          maxW={{ base: "xs", sm: "md" }}
          pt={{ base: "10", sm: "20", lg: "20" }}
        >
          {/* top nav */}
          <VStack spacing="5" alignItems="left">
            <Logo />
            <Nav />
          </VStack>

          {/* main content  */}
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/locations" element={<Locations />} />
            <Route path="/contact" element={<Contact />} />
          </Routes>
        </Container>
      </BrowserRouter>
    </ChakraProvider>
  );
}

export default App;
