import {
  Button,
  Container,
  HStack,
  Heading,
  Text,
  VStack,
} from "@chakra-ui/react";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";

export default function Locations() {
  return (
    <motion.div
      initial={{ y: 20, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      transition={{
        delay: 0.2,
        duration: 1,
        ease: [0.6, -0.05, 0.01, 0.99],
      }}
    >
      <VStack pt="10" spacing="5" alignItems="left">
        <Text fontWeight="bold" mb="5" fontSize="large">
          Catch us if you can! 🏃💨
        </Text>
        <Text fontWeight="semibold">
          Babble is always on the move, bringing our delicious offerings to
          various street fairs and farmers markets across the city.
        </Text>
        <Text fontWeight="semibold">
          Check our schedule below to find out where we’ll be next. We can’t
          wait to serve you your next favorite treat!
        </Text>
      </VStack>
    </motion.div>
  );
}
