import {
  Button,
  Container,
  HStack,
  Heading,
  Icon,
  Text,
  VStack,
} from "@chakra-ui/react";
import { motion } from "framer-motion";
import { FiMail } from "react-icons/fi";
import { useNavigate } from "react-router-dom";

export default function Home() {
  const navigate = useNavigate();

  return (
    <motion.div
      initial={{ y: 20, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      transition={{
        delay: 0.2,
        duration: 1,
        ease: [0.6, -0.05, 0.01, 0.99],
      }}
    >
      <VStack pt="10" spacing="5">
        <Text fontWeight="semibold">
          Babble was born out of a passion for high-quality beverages and sweets
          that spark joy and conversation.
        </Text>
        <Text fontWeight="semibold">
          We roam the streets of new york city, popping up at your favorite
          local events, to serve our handcrafted coffee, matcha, and
          confections.
        </Text>
        <Text fontWeight="semibold">
          Join us on our journey as we bring a taste of creativity and community
          to the streets of nyc.
        </Text>

        <HStack justifyContent="flex-start" w="100%" mt="5" spacing="2">
          <Button
            colorScheme="brand"
            size="sm"
            variant="outline"
            borderRadius="2xl"
            onClick={() => navigate("/locations")}
          >
            Catch us if you can!
          </Button>
          <Button
            colorScheme="brand"
            size="sm"
            variant="outline"
            borderRadius="2xl"
            onClick={() => navigate("/contact")}
            leftIcon={<Icon as={FiMail} />}
          >
            Contact us
          </Button>
        </HStack>
      </VStack>
    </motion.div>
  );
}
