import { Center, HStack, Heading, Link, Text } from "@chakra-ui/react";
import { motion } from "framer-motion";
import { useEffect } from "react";
import { Link as RouterLink } from "react-router-dom";
import { useLocation } from "react-router-dom";

export const Nav = () => {
  // get pathname
  const location = useLocation();
  const { hash, pathname, search } = location;

  const LinkItem = ({ to, label }) => {
    // style for hover on the links
    const hoverStyle = {
      textDecoration: "none",
      color: "brand.500",
      transform: "translateY(-5px)",
      transition: "color 0.3s, transform 0.3s",
    };

    return (
      <Link
        as={RouterLink}
        to={to}
        _hover={pathname !== to && hoverStyle}
        color={pathname == to && "brand.500"}
        fontWeight="semibold"
        fontSize="large"
        fontFamily="mono"
      >
        {label}
      </Link>
    );
  };

  return (
    <motion.div
      initial={{ y: 20, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      transition={{
        duration: 1.2,
        delay: 0.1,
        ease: [0.6, -0.05, 0.01, 0.99],
      }}
    >
      <HStack spacing="5">
        <LinkItem to="/" label="about us" />
        <LinkItem to="/locations" label="locations" />
        <LinkItem to="/contact" label="contact" />
      </HStack>
    </motion.div>
  );
};
